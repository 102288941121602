const styles = {
    container: {
        minWidth: 200,
        flex: 1,
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'flex-end',
    },
    stateVeryBad: { fontWeight: 'bold', color: 'red' },
    stateBad: { color: 'red' },
    stateOk: { color: 'blue' },
    stateGood: { color: 'green' },
};


export default styles;
