import { DashboardTranslations } from '../../types';

const dashboard: DashboardTranslations = {
    'index': {
        action: {
            modal: {
                start: {
                    title: 'Başlat',
                },
                end: {
                    title: 'Bitir',
                },
            },
            filters: {
                branch: {
                    label: 'Şube',
                },
                date: {
                    label: 'Tarih'
                },
            },
            empty: {
                text: '{{date}} tarihi için bir eylem yok.',
                suggestion: 'Lütfen başka bir tarih veya şube seçin. Veya bu gün için eylemler planlayın.',
            },
            card: {
                description: {
                    'should_start': 'Başlamalı',
                    'is_start': 'Başladı',
                    'should_end': 'Bitmeli',
                    'is_end': 'Bitti',
                },
                'extra': {
                    'state': {
                        'planned': 'Planlı',
                        'in_progress': 'Devam Ediyor',
                        'completed': 'Tamamlandı',
                        'early_start': 'Erken Başladı',
                        'late_start': 'Geç Başladı',
                        'late': 'Geç Kaldı',
                        'early_end': 'Erken Bitirdi',
                        'late_end': 'Geç Bitirdi',
                        'no_show': 'Gelmedi',
                    }
                },
            },
        },
    },
    'upcoming-birthdays': {
        title: 'Yaklaşan Doğum Günleri',
    },
    'upcoming-identity-document-expiration-dates': {
        title: 'Yaklaşan Pasaport Bitiş Tarihleri',
    },
    'upcoming-visa-expiration-dates': {
        title: 'Yaklaşan Vize Bitiş Tarihleri',
    },
    'upcoming-contract-expiration-dates': {
        title: 'Yaklaşan Sözleşme Bitiş Tarihleri',
    },
    'upcoming-residence-permit-expiration-dates': {
        title: 'Yaklaşan İkamet İzni Bitiş Tarihleri',
    },
};

export default dashboard;
