import React, { ReactNode, useEffect } from 'react';
import { Avatar, Card, DatePicker, Empty, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useRequest } from 'ahooks';
import { PageContainer, ProList } from '@ant-design/pro-components';
import { LoadingOutlined, ShopOutlined, TeamOutlined, ToolOutlined } from '@ant-design/icons';

import {
    getActions,
    getListBranches,
} from '../../../utils/network/api/v1';
import { DailyActionResource } from '../../../types';
import SuggestedActionBox from '../../../components/SuggestedActionBox';

import styles from './styles';
import { requestConfig } from './config';
import { fmtListToOptions } from './utils';
import Extra from './Extra';
import Description from './Description';
import ActionModal from './ActionModal';

const TodayIndex = () => {
    const { t } = useTranslation('dashboard', {
        keyPrefix: 'index.action',
    });
    const { t: ct } = useTranslation('common');
    const [selectedBranch, setSelectedBranch] = React.useState<number[]>([]);
    const [selectedDate, setSelectedDate] = React.useState(dayjs(new Date()));
    const [selectedAction, setSelectedAction] = React.useState<number | null>(null);

    const {
        data: branches,
        loading: loadingBranches,
    } = useRequest(getListBranches);

    const {
        data,
        loading,
        run: reloadData,
    } = useRequest(
        () => getActions(selectedDate.format('YYYY-MM-DD'), {
            branch_id: selectedBranch,
        }).then(({ data }) => data),
        requestConfig,
    );

    useEffect(() => {
        reloadData();
    }, [reloadData, selectedBranch, selectedDate]);

    const branchList = branches?.data.map(fmtListToOptions) || [];

    const renderTitle = (_n: ReactNode, { id, title }: DailyActionResource) => (
        <b onClick={() => setSelectedAction(id)}>{title}</b>
    );

    const renderAvatar = (_n: ReactNode, { icon, title }: DailyActionResource) =>
        <Avatar src={icon} alt={title} size={64}/>;

    const renderDescription = (_n: ReactNode, action: DailyActionResource) =>
        <Description action={action}/>;

    const renderExtra = (_n: ReactNode, action: DailyActionResource) =>
        <Extra action={action}/>;

    const selectedDateYmd = selectedDate.format(ct('input.date.format'));

    const empty = selectedBranch ? (
        <Empty
            description={(
                <>
                    {t('empty.text', { date: selectedDate.format(ct('input.date.format')) })}
                    <br/>
                    {t('empty.suggestion')}
                    <div style={styles.suggestedActionsContainer}>
                        <SuggestedActionBox
                            title={ct('menu.planning.personnel-planning.title')}
                            link={`/planning/personnel?date=${selectedDateYmd}`}
                            icon={<TeamOutlined style={{ color: '#08c' }}/>}
                            backgroundColor="#f5fbfe"
                        />
                        <SuggestedActionBox
                            title={ct('menu.planning.supply-planning.title')}
                            link={`/planning/supply?date=${selectedDateYmd}`}
                            icon={<ShopOutlined style={{ color: '#52c41a' }}/>}
                            backgroundColor="#f3fcf6"
                        />
                        <SuggestedActionBox
                            title={ct('menu.planning.service-planning.title')}
                            link={`/planning/service?date=${selectedDateYmd}`}
                            icon={<ToolOutlined style={{ color: '#eb2f96' }}/>}
                            backgroundColor="#fef7f6"
                        />
                    </div>
                </>
            )}
        />
    ) : (
        <Empty description="Bitte wählen Sie eine Filiale aus."/>
    );

    const list = <ProList
        loading={!data && loading}
        dataSource={data?.data!}
        metas={{
            title: { render: renderTitle },
            avatar: { render: renderAvatar },
            description: { render: renderDescription },
            extra: { render: renderExtra },
        }}
    />;

    const datePicker = (
        <DatePicker
            onChange={setSelectedDate}
            format={ct('input.date.format')}
            style={styles.datePicker}
            value={selectedDate}
        />
    );

    const branchSelect = (
        <Select
            value={selectedBranch}
            placeholder={ct('input.filter.placeholder')}
            style={styles.branchSelect}
            options={branchList}
            loading={loadingBranches}
            onChange={setSelectedBranch}
            allowClear
        />
    );

    const title = (
        <>
            <span style={{ marginRight: 20 }}>{t('filters.date.label')}: {datePicker}</span>
            <span>{t('filters.branch.label')}: {branchSelect}</span>
        </>
    );

    const extra = (
        loading ? (
            <div style={styles.loadingContainer}>
                <LoadingOutlined spin={true} style={styles.loading}/>
                {ct('loading')}
            </div>
        ) : null
    );

    return (
        <>
            <PageContainer>
                <Card title={title} extra={extra}>
                    {data && data?.data.length === 0 ? empty : list}
                </Card>
            </PageContainer>
            {selectedAction && (
                <ActionModal
                    open={!!selectedAction}
                    handleOk={() => alert('ok')}
                    confirmLoading={false}
                    handleCancel={() => setSelectedAction(null)}
                    action={data?.data.find((o) => o.id === selectedAction)!}
                />
            )}
        </>
    );
};

export default TodayIndex;
