import { DashboardTranslations } from '../../types'

const dashboard: DashboardTranslations = {
    'index': {
        'action': {
            'modal': {
                'start': {
                    'title': 'Start Action',
                },
                'end': {
                    'title': 'End Action',
                },
            },
            'filters': {
                'branch': {
                    'label': 'Branch',
                },
                'date': {
                    'label': 'Date',
                },
            },
            'empty': {
                'text': 'There are no actions for {{date}}.',
                'suggestion': 'Please select another date or branch. Or plan actions for this day.',
            },
            'card': {
                'description': {
                    'should_start': 'Should Start',
                    'is_start': 'Is Started',
                    'should_end': 'Should End',
                    'is_end': 'Is Ended',
                },
                'extra': {
                    'state': {
                        'planned': 'Planned',
                        'in_progress': 'In Progress',
                        'completed': 'Completed',
                        'early_start': 'Early Start',
                        'late_start': 'Late Start',
                        'late': 'Late',
                        'early_end': 'Early End',
                        'late_end': 'Late End',
                        'no_show': 'No Show',
                    }
                },
            },
        },
    },
    'upcoming-birthdays': {
        title: 'Upcoming Birthdays',
    },
    'upcoming-identity-document-expiration-dates': {
        title: 'Upcoming Passport Expiration Dates',
    },
    'upcoming-visa-expiration-dates': {
        title: 'Upcoming Visa Expiration Dates',
    },
    'upcoming-contract-expiration-dates': {
        title: 'Upcoming Contract Expiration Dates',
    },
    'upcoming-residence-permit-expiration-dates': {
        title: 'Upcoming Residence Permit Expiration Dates',
    },
};

export default dashboard;
