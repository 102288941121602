import React from 'react';
import dayjs from 'dayjs';
import { Progress } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './styles';
import { ExtraProps } from './types';

const goodStates = ['in_progress', 'completed'];
const okStates = ['planned'];
const badStates = ['early_start', 'late_start', 'late', 'early_end', 'late_end'];
const veryBadStates = ['no_show'];


const Extra: React.FC<ExtraProps> = ({ action }) => {
    const { t } = useTranslation('dashboard', {
        keyPrefix: 'index.action.card.extra',
    });

    const now = dayjs(new Date());
    const {
        scheduled_start_date,
        actual_start_date,
        scheduled_end_date,
        actual_end_date,
        state,
    } = action;

    const scheduledStartDate = dayjs(scheduled_start_date);
    const actualStartDate = actual_start_date ? dayjs(actual_start_date) : null;
    const scheduledEndDate = dayjs(scheduled_end_date);
    const actualEndDate = actual_end_date ? dayjs(actual_end_date) : null;

    // const goodEnd = actualEndDate?.isAfter(scheduledEndDate) || actualEndDate?.isSame(scheduledEndDate);
    // const isLateEnd = shouldEnd && !actualEndDate;
    const workMinutes = scheduledEndDate.diff(scheduledStartDate, 'minute');

    let completedMinutes;
    if (actualEndDate) {
        completedMinutes = actualEndDate.diff(dayjs(actualStartDate), 'minute');
    } else if (actualStartDate) {
        completedMinutes = actualStartDate.diff(dayjs(now), 'minute');
    } else {
        completedMinutes = 0;
    }

    const percentageMinutes = parseFloat(Number((completedMinutes / workMinutes) * 100).toFixed(0));

    let stateSytles;
    if (veryBadStates.includes(state)) {
        stateSytles = styles.stateVeryBad;
    } else if (badStates.includes(state)) {
        stateSytles = styles.stateBad;
    } else if (okStates.includes(state)) {
        stateSytles = styles.stateOk;
    } else if (goodStates.includes(state)) {
        stateSytles = styles.stateGood;
    } else {
        stateSytles = {};
    }

    return (
        <div style={styles.container}>
            <div style={{ width: 200 }}>
                <span style={stateSytles}>
                    {t(`state.${state}`)}
                </span>
            </div>
            <Progress percent={percentageMinutes}/>
        </div>
    );
};

export default Extra;
